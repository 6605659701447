import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Designs from './Designs';
import { CORE_DATA } from '../data';

const Gallery = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <>
            <div className='display'>
                <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                        <h2>Film & TV Designs</h2>
                    </div>
                        <Designs {...CORE_DATA[16]}/>
                        <Designs {...CORE_DATA[44]}/>
                        <Designs {...CORE_DATA[21]}/>
                    <h5><Link to='/moviedesigns'>See more...</Link></h5>
                </motion.div>
                <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                    <h2>Literary Designs</h2>
                    </div>
                        <Designs {...CORE_DATA[0]}/>
                        <Designs {...CORE_DATA[5]}/>
                        <Designs {...CORE_DATA[56]}/>
                    <h5><Link to='/literarydesigns'>See more...</Link></h5>
                </motion.div>
                <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                        <h2>Philosophy Designs</h2>
                    </div>
                        <Designs {...CORE_DATA[26]}/>
                        <Designs {...CORE_DATA[27]}/>
                        <Designs {...CORE_DATA[28]}/>
                    <h5><Link to='/philosophydesigns'>See more...</Link></h5>
                </motion.div>
                <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                        <h2>Art Designs</h2>
                    </div>
                        <Designs {...CORE_DATA[52]}/>
                        <Designs {...CORE_DATA[49]}/>
                        <Designs {...CORE_DATA[32]}/>
                    <h5><Link to='/artdesigns'>See more...</Link></h5>
                </motion.div>
                <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                        <h2>Music Designs</h2>
                    </div>
                        <Designs {...CORE_DATA[84]}/>
                        <Designs {...CORE_DATA[85]}/>
                        <Designs {...CORE_DATA[6]}/>
                    <h5><Link to='/musicdesigns'>See more...</Link></h5>
                    </motion.div>
                    <motion.div className='gallery' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className='item-title'>
                        <h2>Seasonal Designs</h2>
                        </div>
                        <Designs {...CORE_DATA[46]}/>
                        <Designs {...CORE_DATA[70]}/>
                        <Designs {...CORE_DATA[71]}/>
                        <h5><Link to='/seasonaldesigns'>See more...</Link></h5>
                    </motion.div>
            </div>
        </>
    )
}

export default Gallery;