import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";

const Contact = () => {
    const variants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut' }
        }
    }

    return(
        <div className="App">
            <Helmet>
                <title>Contact Form for CosmicFandom - Contact for Creative Designs</title>
                <meta
                    name="description"
                    content="Contact CosmicFandom here with your enquiries, requests and suggestions."
                />
                <link rel='canonical' href='https://cosmicfandom.com/tees' />
            </Helmet>
            <div className="App-header">
                <Navbar />
                <div className='home'>
                    <motion.div className="home-title" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h2>Cosmic Creations</h2>
                        <h3>Contact Us</h3>
                    </motion.div>
                    <motion.header className="home-text" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h1>Contact Us</h1>
                        <p>If you would like to request a custom-made design, or have any other queries, fill in the form and we'll get back to you.</p> 
                    </motion.header>
                </div>
            </div>
        <motion.div className='contact-box' variants={variants} initial="hidden" animate="visible">
            <div className='contact'>
                <Form />
            </div>
        </motion.div>
        <Footer />
        </div>
    )
}

export default Contact;