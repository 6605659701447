import { Link } from "react-router-dom";

const Designs = ({image_a, image_b, image_c, title, rblink, tlink}) => {
    return(
        <div className='item'>
            <img src={image_a} alt={title} />
            <h4>{title}</h4>
            <h4>Purchase from:</h4>
            <Link to={rblink} target="_blank"><img src={image_b} alt={title} /></Link>
            <h4>or</h4>
            <Link to={tlink} target='_blank'><img src={image_c} alt={title} /></Link>
        </div>
    )
}

export default Designs;