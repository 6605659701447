import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const About = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }
    
    return (
        <div className="App">
            <Helmet>
                <title>All About CosmicFandom - Pop Inspired Creative Designer</title>
                <meta
                    name="description"
                    content="Read all about the mission of CosmicFandom, the designer who is inspired by a love of music, movies, TV, literature and art."
                />
                <link rel='canonical' href='https://cosmicfandom.com/about' />
            </Helmet>
            <div className="App-header">
                <Navbar />
                <div className='home'>
                    <motion.div className="home-title" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h2>Cosmic Creations</h2>
                        <h3>About Us</h3>
                    </motion.div>
                    <motion.header className="home-text" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h1>Who We Are</h1>
                        <p>The Cosmic Creations Store is created and run by CosmicFandom, a Scottish designer with a passion for all things pop culture.</p>
                        <p>CosmicFandom employs ingenuity and an attention to detail to offer out of this world designs that fans will love.</p>
                        <h2>What We Do</h2>
                        <p>We are committed to creating unique designs that cater for the tastes of a wide range of fan based interests in the areas of Music, TV, Film and Art. 
                            These designs are made available on range of products for sale through RedBubble and TeePublic.</p>
                            <p>You can window shop the available designs here on a range of templates and click the links to our sales pages to make a purchase.</p>
                            <p>Where our designs use licensed logos and images, they will have undergone an approval process and will constitute legally sanctioned fan art. They will be copyright marked to the affiliate company.</p>
                        <h2>Our Promise to You</h2>
                        <p>It is our promise to you that we will take the utmost care in ensuring that our designs are of the highest quality.</p>
                        <p>The printing of designs is undertaken by our affiliates, so it is there responsibility to ensure that the products shipped to you are in excellent condition. 
                            Complaints about product quality should be directed toward them.</p>
                            <p>However, we do care about the quality of the product that you recieve. Therefore, if the quality of the product is not upto an acceptable standard, we would like to know through our contact page
                            so that we can do all that we can to make the improvements that ensure the best service possible in the future.</p>
                    </motion.header>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;