import austentatious from './images/austentatious.webp';
import karma from './images/karma.webp';
import blankspace from './images/blankspace.webp';
import snowbeach from './images/blankspace.webp';
import cowboy from './images/cowboy.webp';
import pemberley from './images/pemberley.webp';
import wildestdreams from './images/wilddreams.webp';
import tea from './images/tea.webp';
import cruelsummer from './images/cruel-summer.webp';
import itsme from './images/itsme.webp';
import theman from './images/man-tee.webp';
import ubelong from './images/u-belong.webp';
import lovestory from './images/lovestory.webp';
import overdramatic from './images/overdramatic.webp';
import darklight from './images/darklight.webp';
import spectrum from './images/spectrum.webp';
import bigleb from './images/big-leb.webp';
import pandp from './images/pride&prejudice.webp';
import seven from './images/seven.webp';
import overwhelmed from './images/overwhelmed.webp';
import don from './images/fashioned.webp';
import bttf from './images/btf.webp';
import oldfash from './images/oldfash.webp';
import kant from './images/kant.webp';
import bahaus from './images/bauhaus.webp';
import deco from './images/deco.webp';
import socrates from './images/socrates.webp';
import camus from './images/camus.webp';
import hume from './images/hume.webp';
import descartes from './images/descartes.webp';
import rationalcreatures from './images/austen-rationalcreature.webp';
import nietzsche from './images/nietzsche.webp';
import thistle from './images/thistle.webp';
import bored from './images/bored.webp';
import runningman from './images/runningman.webp';
import theone from './images/theone.webp';
import bicker from './images/bicker.webp';
import doubledown from './images/doubledown.webp';
import babylonlips from './images/babylonlips.webp';
import snowmen from './images/snowmen.webp';
import possibleworlds from './images/possibleworlds.webp';
import aiart from './images/aiart.webp';
import sympathique from './images/sympathique.webp';
import apocalypse from './images/apocalypse.webp';
import america from './images/america.webp';
import dowjones from './images/dowjones.webp';
import christmastree from './images/christmastree.webp';
import xmastrees from './images/xmastrees.webp';
import lovebirds from './images/lovebirds.webp';
import coffee from './images/coffee.webp';
import gardencreatures from './images/gardencreatures.webp';
import coffeeseason from './images/coffee-seasons.webp';
import timespace from './images/time-space.webp';
import coffeebean from './images/cafe-bean.webp';
import coffeesqueeze from './images/squeezed.webp';
import monster from './images/monster.webp';
import worldstage from './images/world-stage.webp';
import oyster from './images/oyster.webp';
import breakice from './images/break-ice.webp';
import hearts from './images/hearts.webp';
import goldheart from './images/gold-heart.webp';
import doornail from './images/doornail.webp';
import greek from './images/greek.webp';
import goosechase from './images/goose-chase.webp';
import reputation from './images/reputation.webp';
import heartsleeve from './images/heart-sleeve.webp';
import december from './images/december.webp';
import sound from './images/sounds.webp';
import red from './images/red.webp';
import maxim from './images/maxim.webp';
import rudolph from './images/rudolph.webp';
import santa from './images/santa.webp';
import xmaslights from './images/xmaslights.webp';
import antihero from './images/antihero.webp';
import redlips from './images/classicred.webp';
import socialbattery from './images/socialbattery.webp';
import clogs from './images/clogs.webp';
import tags from './images/tags.webp';
import object from './images/object.webp';
import afunction from './images/afunction.webp';
import different from './images/different.webp';
import softwaredev from './images/softwaredev.webp';
import willow from './images/willow.webp';
import austenbooks from './images/austenbooks.webp';
import shakeitoff from './images/shakeitoff.webp';
import taylorsversion from './images/taylorsversion1.webp';
import redbubble from './images/redbubble-red.webp';
import teepublic from './images/teepublic.webp';

export const CORE_DATA = [
    //0
    {
        image_a: austentatious,
        title: 'Austentatious',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149072597',
        tlink: 'https://www.teepublic.com/sticker/49701674-jane-austen-emma-quote-and-silhouette'
    },
    //1
    {
        image_a: karma,
        title: 'Me and Karma Vibe Like That',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149265694',
        tlink: 'https://www.teepublic.com/sticker/49701776-taylor-swift-me-and-karma-vibe-like-that'
    },
    //2
    {
        image_a: blankspace,
        title: 'Blank Space',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149076303',
        tlink: '#'
    },
    //3
    {
        image_a: snowbeach,
        title: 'Snow on the Beach',
        image_b: redbubble,
        image_c: teepublic
    },
    //4
    {
        image_a: cowboy,
        title: 'Cowboy Like Me',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149082724',
        tlink: '#'
    },
    //5
    {
        image_a: pemberley,
        title: 'Mistress of Pemberley',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149085013',
        tlink: 'https://www.teepublic.com/sticker/52751371-jane-austen-mistress-of-pemberley'
    },
    //6
    {
        image_a: wildestdreams,
        title: 'Just In Your Wildest Dreams',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149085985',
        tlink: 'https://www.teepublic.com/sticker/49947560-taylor-swift-wildest-dreams-staring-at-the-sunset'
    },
    //7
    {
        image_a: tea,
        title: 'I Would Rather Have Tea',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149086781',
        tlink: '#'
    },
    //8
    {
        image_a: itsme,
        title: 'Everybody Agrees',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149089893',
        tlink: '#'
    },
    //9
    {
        image_a: cruelsummer,
        title: 'Cruel Summer',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149089010',
        tlink: 'https://www.teepublic.com/t-shirt/50297872-taylor-swift-cruel-summer?store_id=2837086'
    },
    //10
    {
        image_a: ubelong,
        title: 'You Belong to Me',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149126479',
        tlink: 'https://www.teepublic.com/sticker/50298372-taylor-swift-you-belong-with-me'
    },
    //11
    {
        image_a: theman,
        title: 'The Man',
        image_b: redbubble,
        image_c: teepublic
    },
    //12
    {
        image_a: lovestory,
        title: 'Love Story',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149135403',
        tlink: '#'
    },
    //13
    {
        image_a: overdramatic,
        title: 'Overdramatic and True to My Lover',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149137406',
        tlink: '#'
    },
    //14
    {
        image_a: darklight,
        title: 'Dark Mode Light Mode',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149143257',
        tlink: '#'
    },
    //15
    {
        image_a: spectrum,
        title: 'The Autistic Spectrum',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149169821',
        tlink: 'https://www.teepublic.com/sticker/52750872-the-autistic-spectrum'
    },
    //16
    {
        image_a: bigleb,
        title: 'The Big Lebowski',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149256808',
        tlink: 'https://www.redbubble.com/shop/ap/149256808'
    },
    //17
    {
        image_a: pandp,
        title: 'Pride and Prejudice',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149291289',
        tlink: 'https://www.teepublic.com/t-shirt/49701903-pride-and-prejudice-jane-austen?store_id=2837086'
    },
    //18
    {
        image_a: seven,
        title: 'Seven',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149320570',
        tlink: 'https://www.teepublic.com/sticker/49735996-taylor-swift-seven-girl-swings-over-the-creek'
    },
    //19
    {
        image_a: overwhelmed,
        title: 'Overwhelmed By Technology',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149417303',
        tlink: 'https://www.teepublic.com/t-shirt/49700951-overwhelmed-by-technology?store_id=2837086'
    },
    //20
    {
        image_a: don,
        title: 'Don Sitting At the Bar',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/149734649',
        tlink: 'https://www.teepublic.com/sticker/49949910-old-fashioned-don-sitting-at-the-bar'
    },
    //21
    {
        image_a: bttf,
        title: 'Back to the Future',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150027956',
        tlink: 'https://www.redbubble.com/shop/ap/150027956'
    },
    //22
    {
        image_a: oldfash,
        title: 'Old Fashioned',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150517505',
        tlink: '#'
    },
    //23
    {
        image_a: kant,
        title: 'Immanuel Kant - Categorically Out of Imperatives',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150721957',
        tlink: 'https://www.teepublic.com/t-shirt/49701336-immanuel-kant-categorically-out-of-imperatives?store_id=2837086'
    },
    //24
    {
        image_a: bahaus,
        title: 'Portrait of a Restaurant',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150761385',
        tlink: 'https://www.teepublic.com/sticker/49701144-1920s-portrait-of-a-restaurant'
    },
    //25
    {
        image_a: deco,
        title: 'Art Deco Skyline',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150776393',
        tlink: 'https://www.teepublic.com/sticker/50080642-art-deco-skyline-gold-green-purple'
    },
    //26
    {
        image_a: socrates,
        title: 'Socrates - Hit the Hemlock',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150786123',
        tlink: 'https://www.teepublic.com/t-shirt/49701337-socrates-hit-the-hemlock?store_id=2837086'
    },
    //27
    {
        image_a: camus,
        title: 'Albert Camus - Existential Crisis',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150787383',
        tlink: 'https://www.teepublic.com/sticker/49736353-albert-camus-existential-crisis-averted'
    },
    //28
    {
        image_a: hume,
        title: 'David Hume - No Ought From Is Not',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150819144',
        tlink: 'https://www.teepublic.com/sticker/49701335-david-hume-no-ought-from-is-not'
    },
    //29
    {
        image_a: descartes,
        title: 'Rene Descartes - No Longer Thinking',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150827256',
        tlink: 'https://www.teepublic.com/sticker/49701334-rene-descartes-no-longer-thinking'
    },
    //30
    {
        image_a: rationalcreatures,
        title: 'Jane Austen - Rational Creatures',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/150908082',
        tlink: 'https://www.teepublic.com/t-shirt/49671125-jane-austen-persuasion-rational-creatures?store_id=2837086'
    },
    //31
    {
        image_a: nietzsche,
        title: 'Friedrich Nietzsche - Ubermensh Is Dead',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/151058028',
        tlink: 'https://www.teepublic.com/sticker/49741112-friedrich-nietzsche-ubermensch-is-dead'
    },
    //32
    {
        image_a: thistle,
        title: 'Tartan Thistle',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/151494577',
        tlink: 'https://www.teepublic.com/sticker/50043273-tartan-thistle-on-a-chequered-background'
    },
    //33
    {
        image_a: bored,
        title: 'Bored Of the Board',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/151541511',
        tlink: 'https://www.teepublic.com/sticker/50077299-bored-of-the-board'
    },
    //34
    {
        image_a: runningman,
        title: 'Daizen Maeda - The Running Man',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/151693333',
        tlink: 'https://www.teepublic.com/sticker/50200936-daizen-maeda-the-running-man'
    },
    //35
    {
        image_a: theone,
        title: 'Kyogo Furuhashi - The One',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/151884230',
        tlink: 'https://www.teepublic.com/sticker/50448649-celtic-kyogo-the-one'
    },
    //36
    {
        image_a: bicker,
        title: "Monty Python - Let's Not Bicker and Argue",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152025386',
        tlink: 'https://www.teepublic.com/t-shirt/50455237-lets-not-bicker-and-argue-holy-grail?store_id=2837086'
    },
    //37
    {
        image_a: doubledown,
        title: "Don't Back Down. Double Down",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152072991',
        tlink: 'https://www.teepublic.com/sticker/50488654-dont-back-down-double-down'
    },
    //38
    {
        image_a: babylonlips,
        title: 'Hang From Babylon Lips',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152098607',
        tlink: 'https://www.teepublic.com/sticker/50520975-hang-from-lips-like-the-gardens-of-babylon'
    },
    //39
    {
        image_a: snowmen,
        title: 'Do You Smell Carrots',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152141167',
        tlink: 'https://www.teepublic.com/sticker/50537469-christmas-snowmen'
    },
    //40
    {
        image_a: possibleworlds,
        title: 'The Best Of All Possible Worlds',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152258724',
        tlink: 'https://www.teepublic.com/sticker/50626913-best-of-all-possible-worlds'
    },
    //41
    {
        image_a: aiart,
        title: 'AI Art Will Eat Itself',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/152413805',
        tlink: 'https://www.redbubble.com/shop/ap/152413805'
    },
    //42
    {
        image_a: sympathique,
        title: 'Sympathique',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153005109',
        tlink: 'https://www.teepublic.com/sticker/51202505-sympathique'
    },
    //43
    {
        image_a: apocalypse,
        title: 'Apocalypse Always',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153204348',
        tlink: 'https://www.teepublic.com/t-shirt/51387749-apocalypse-always?store_id=2837086'
    },
    //44
    {
        image_a: dowjones,
        title: 'Dow Jones and the Temple of Doom',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153206250',
        tlink: 'https://www.teepublic.com/sticker/51389558-dow-jones-and-the-temple-of-doom'
    },
    //45
    {
        image_a: america,
        title: 'Coming to America',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153205581',
        tlink: 'https://www.teepublic.com/sticker/51388839-the-american-dream'
    },
    //46
    {
        image_a: christmastree,
        title: 'Christmas Tree',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153281998',
        tlink: 'https://www.teepublic.com/sticker/51473801-christmas-tree'
    },
    //47
    {
        image_a: xmastrees,
        title: 'Christmas Trees',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153288186',
        tlink: 'https://www.teepublic.com/sticker/51481151-christmas-trees'
    },
    //48
    {
        image_a: lovebirds,
        title: 'Winter Love Birds',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153291002',
        tlink: 'https://www.teepublic.com/sticker/51490120-winter-love-birds'
    },
    //49
    {
        image_a: coffee,
        title: 'Coffee',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153355379',
        tlink: 'https://www.teepublic.com/sticker/51566313-coffee'
    },
    //50
    {
        image_a: coffeebean,
        title: 'Coffee Bean',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153523059',
        tlink: 'https://www.teepublic.com/sticker/51566313-coffee'
    },
    //51
    {
        image_a: coffeesqueeze,
        title: 'Freshly Squeezed Coffee',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153749210',
        tlink: 'https://www.teepublic.com/t-shirt/52043050-freshly-squeezed-coffee?store_id=2837086'
    },
    //52
    {
        image_a: gardencreatures,
        title: 'Garden Creatures',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153379311',
        tlink: 'https://www.redbubble.com/shop/ap/153379311'
    },
    //53
    {
        image_a: coffeeseason,
        title: 'Coffee Seasons',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153400882',
        tlink: 'https://www.redbubble.com/shop/ap/153400882'
    },
    //54
    {
        image_a: timespace,
        title: 'Time and Space',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153517382',
        tlink: 'https://www.teepublic.com/sticker/51766326-time-and-space-solar-system'
    },
    //55
    {
        image_a: monster,
        title: 'Shakespeare - Green Eyed Monster',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153843817',
        tlink: 'https://www.teepublic.com/sticker/52164257-shakespeare-beware-the-green-eyed-monster'
    },
    //56
    {
        image_a: worldstage,
        title: "Shakespeare - All the World's A Stage",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153865822',
        tlink: 'https://www.teepublic.com/sticker/52164117-william-shakespeare-all-the-worlds-a-stage'
    },
    //57
    {
        image_a: oyster,
        title: "Shakespeare - The World's Mine Oyster",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153872167',
        tlink: 'https://www.teepublic.com/sticker/52165903-william-shakespeare-the-worlds-mine-oyster'
    },
    //58
    {
        image_a: breakice,
        title: 'Shakespeare - Break the Ice',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153875251',
        tlink: 'https://www.teepublic.com/sticker/52168450-william-shakespeare-break-the-ice'
    },
    //59
    {
        image_a: greek,
        title: 'ShakesPeare - Greek to Me',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153900286',
        tlink: 'https://www.teepublic.com/sticker/52190152-william-shakespeare-its-greek-to-me'
    },
    //60
    {
        image_a: hearts,
        title: 'Shakespeare - In My Heart of Hearts',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153885196',
        tlink: 'https://www.teepublic.com/sticker/52188694-william-shakespeare-in-my-heart-of-hearts'
    },
    //61
    {
        image_a: heartsleeve,
        title: 'Shakespeare - Wear My Heart Upon My Sleeve',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153988174',
        tlink: 'https://www.teepublic.com/sticker/52283489-william-shakespeare-wear-my-heart-upon-my-sleeve'
    },
    //62
    {
        image_a: goldheart,
        title: 'Shakespeare - A Heart of Gold',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153886554',
        tlink: 'https://www.teepublic.com/poster-and-art/52188286-william-shakespeare-a-heart-of-gold'
    },
    //63
    {
        image_a: doornail,
        title: 'Shakespeare - As Dead As a Doornail',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153898668',
        tlink: 'https://www.teepublic.com/sticker/52188199-william-shakespeare-as-dead-as-a-doornail'
    },
    //64
    {
        image_a: goosechase,
        title: 'Shakespeare - A Wild Goose Chase',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153946932',
        tlink: 'https://www.teepublic.com/sticker/52238864-william-shakespeare-a-wild-goose-chase'
    },
    //65
    {
        image_a: reputation,
        title: 'Shakespeare - Spotless Reputation',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/153956295',
        tlink: 'https://www.redbubble.com/shop/ap/153956295'
    },
    //66
    {
        image_a: december,
        title: "Back to December - Taylor's Version",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154204523',
        tlink: 'https://www.teepublic.com/sticker/52493802-back-to-december-taylors-version'
    },
    //67
    {
        image_a: sound,
        title: 'The Mechanics of Sound',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154397451',
        tlink: 'https://www.teepublic.com/sticker/52713428-the-mechanics-of-sound'
    },
    //68
    {
        image_a: red,
        title: "Red - Taylor's Version",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154407123',
        tlink: 'https://www.teepublic.com/sticker/52867747-taylors-version-red'
    },
    //69
    {
        image_a: maxim,
        title: 'Adam Smith - The Vile Maxim',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154479314',
        tlink: 'https://www.teepublic.com/sticker/52790065-the-vile-maxim-adam-smith'
    },
    //70
    {
        image_a: rudolph,
        title: 'Merry Xmas Rudolph',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154510938',
        tlink: 'https://www.teepublic.com/sticker/52813299-merry-xmas-rudolph'
    },
    //71
    {
        image_a: santa,
        title: 'Santa to Rudolph - Prepare the Sleigh',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154545163',
        tlink: 'https://www.teepublic.com/sticker/52836286-santa-to-rudolph-prepare-the-sleigh'
    },
    //72
    {
        image_a: xmaslights,
        title: "Leave the Christmas Lights Up 'til January",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154572935',
        tlink: 'https://www.teepublic.com/sticker/52875155-leave-the-christmas-lights-up-til-january'
    },
    //73
    {
        image_a: antihero,
        title: "I'm Your Antihero",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154613665',
        tlink: 'https://www.teepublic.com/sticker/52911991-im-your-antihero'
    },
    //74
    {
        image_a: redlips,
        title: 'Classic Red Lips style',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154660494',
        tlink: 'https://www.teepublic.com/sticker/52958627-classic-red-lips-style'
    },
    //75
    {
        image_a: socialbattery,
        title: 'Social Battery',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154736171',
        tlink: 'https://www.teepublic.com/sticker/53034628-social-battery-this-is-normal'
    },
    //76
    {
        image_a: clogs,
        title: 'Clever Clogs',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154828506',
        tlink: 'https://www.teepublic.com/sticker/53124759-clever-clogs'
    },
    //77
    {
        image_a: object,
        title: 'Object()',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154849681',
        tlink: 'https://www.teepublic.com/sticker/53138512-the-code-object'
    },
    //78
    {
        image_a: afunction,
        title: 'Anonymous Function',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154849839',
        tlink: 'https://www.teepublic.com/t-shirt/53139138-anonymous-function?store_id=2837086'
    },
    //79
    {
        image_a: tags,
        title: 'Classic HTML tags',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154838309',
        tlink: 'https://www.teepublic.com/sticker/53130968-classic-coding-tags'
    },
    //80
    {
        image_a: different,
        title: 'Different',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154823279',
        tlink: 'https://www.teepublic.com/sticker/53118590-different'
    },
    //81
    {
        image_a: softwaredev,
        title: 'The Life of a Software Developer',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154882522',
        tlink: 'https://www.teepublic.com/sticker/53173497-the-life-of-a-software-developer'
    },
    //82
    {
        image_a: willow,
        title: 'Willow',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154915555',
        tlink: 'https://www.teepublic.com/sticker/53209749-willow-taylor-swift'
    },
    //83
    {
        image_a: austenbooks,
        title: 'Jane Austen Books',
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/154950653',
        tlink: 'https://www.teepublic.com/sticker/53244821-jane-austen-books'
    },
    //84
    {
        image_a: shakeitoff,
        title: "Shake It Off - Taylor's Version",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/155234195',
        tlink: 'https://www.teepublic.com/sticker/53537495-shake-it-off-taylors-version'
    },
    //85
    {
        image_a: taylorsversion,
        title: "Taylor's Version - Eras Tour Albums",
        image_b: redbubble,
        image_c: teepublic,
        rblink: 'https://www.redbubble.com/shop/ap/155599777',
        tlink: 'https://www.teepublic.com/sticker/53960319-taylors-version-eras-tour-albums'
    }
]