import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import { CORE_DATA } from "../data";
import Designs from "../components/Designs";

const ArtDesigns = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <>
            <div className="App">
                <Helmet>
                    <title>Art Designs - Creative Designs from the World of Art</title>
                    <meta
                        name="description"
                        content="Shop your favorite uniquely crafted artistic designs inspired by a love of different art movements. All available for purchase on an extensive range of products."
                    />
                    <link rel='canonical' href='https://cosmicfandom.com/artdesigns' />
                </Helmet>
                <div className="App-header">
                    <Navbar />
                    <div className='home'>
                        <div className="home-title">
                            <h2>Cosmic Creations</h2>
                            <h3>Art Inspired Designs</h3>
                        </div>
                        <header className="home-text">
                            <h1>Art Designs</h1>
                            <p>We specialise in designing unique pop culture art beautifully presented
                                on a range of everyday, must have, accessories and drinkware.</p>
                            <p>Be sure to check out how the designs look on t-shirts, hats, mugs and posters.</p> 
                        </header>
                    </div>
                </div>
                <div className="display">
                <motion.div className='designs' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className="contain">
                        <Designs {...CORE_DATA[24]}/>
                        <Designs {...CORE_DATA[38]}/>
                        <Designs {...CORE_DATA[32]}/>
                        <Designs {...CORE_DATA[25]}/>
                        <Designs {...CORE_DATA[41]}/>
                        <Designs {...CORE_DATA[42]}/>
                        <Designs {...CORE_DATA[48]}/>
                        <Designs {...CORE_DATA[49]}/>
                        <Designs {...CORE_DATA[50]}/>
                        <Designs {...CORE_DATA[51]}/>
                        <Designs {...CORE_DATA[52]}/>
                        <Designs {...CORE_DATA[53]}/>
                    </div>
                </motion.div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ArtDesigns;