import { Link, NavLink } from 'react-router-dom';
import redbubble from '../images/redbubble-red.webp';
import teepublic from '../images/teepublic.webp';

const Footer = () => {
    return(
        <div className='footer'>
            <div className='footer-main'>
                <p>All designs are under copyright ownership of CosmicFandom unless otherwise labled on the design.</p>
                <Link to='https://www.redbubble.com/people/Cosmic-Fandom/shop?asc=u' target="_blank"><img src={redbubble} alt='redbubble' /></Link>
                <Link to='https://www.teepublic.com/user/cosmic-fandom' target="_blank"><img src={teepublic} alt='teepublic' /></Link>
            </div>
            <div className='footer-links'>
                <ul>
                    <li><NavLink to='/about'>About</NavLink></li>
                    <li><NavLink to='/contact'>Contact Us</NavLink></li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;