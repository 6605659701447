import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDmm82JFQPD5dSqbxChNqPn5rSlHaofyPo",
    authDomain: "cosmic-fandom.firebaseapp.com",
    projectId: "cosmic-fandom",
    storageBucket: "cosmic-fandom.appspot.com",
    messagingSenderId: "673871907448",
    appId: "1:673871907448:web:6ef171d1487e68a0067b8d",
    measurementId: "G-9C4V8NSZJ7"
  };

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);