import Navbar from "../components/Navbar"
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { CORE_DATA } from "../data";
import Designs from "../components/Designs";

const LiteraryDesigns = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <div className="App">
            <Helmet>
                <title>Literary Designs - Creative Designs from the World of Literature</title>
                <meta
                    name="description"
                    content="Shop your favorite uniquely crafted literary designs inspired by a love of literature and art. All available for purchase on an extensive range of products."
                />
                <link rel='canonical' href='https://cosmicfandom.com/literarydesigns' />
            </Helmet>
            <div className="App-header">
                <Navbar />
                <div className='home'>
                    <div className="home-title">
                        <h2>Cosmic Creations</h2>
                        <h3>Literary Inspired Designs</h3>
                    </div>
                    <header className="home-text">
                        <h1>Literary Designs</h1>
                        <p>We specialise in designing unique pop culture art beautifully presented
                            on a range of everyday, must have, accessories and drinkware.</p>
                        <p>Be sure to check out how the designs look on t-shirts, hats, mugs and posters.</p> 
                    </header>
                </div>
            </div>
            <div className="display">
            <motion.div className='designs1' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                <div className="contain">
                    <Designs {...CORE_DATA[0]}/>
                    <Designs {...CORE_DATA[5]}/>
                    <Designs {...CORE_DATA[7]}/>
                    <Designs {...CORE_DATA[17]}/>
                    <Designs {...CORE_DATA[30]}/>
                    <Designs {...CORE_DATA[83]}/>
            </div>
            </motion.div>
            </div>
            <div className="display">
            <motion.div className='designs1' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                <div className="contain">
                    <Designs {...CORE_DATA[56]}/>
                    <Designs {...CORE_DATA[57]}/>
                    <Designs {...CORE_DATA[58]}/>
                    <Designs {...CORE_DATA[59]}/>
                    <Designs {...CORE_DATA[60]}/>
                    <Designs {...CORE_DATA[61]}/>
                    <Designs {...CORE_DATA[62]}/>
                    <Designs {...CORE_DATA[63]}/>
                    <Designs {...CORE_DATA[64]}/>
                    <Designs {...CORE_DATA[65]}/>
            </div>
            </motion.div>
            </div>
            <Footer />
        </div>
    )
}

export default LiteraryDesigns;