import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
import { HelmetProvider } from 'react-helmet-async';
import Provider from './context/ThemeContext';

const TRACKING_ID = 'G-NK11R0DGQG';
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: "/", title: "home" });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider>
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>
  </Provider>
);