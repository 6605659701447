import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import logo from '../images/logo.webp';

const Navbar = () => {
    return (
        <nav className="nav">
        <div className='icons'>
            <IconContext.Provider
                value={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '5px', color: '#bbb', margin: '0 10px', fontSize: '30px' } }}
            >
                <Link to='https://www.facebook.com/profile.php?id=100094782772940' target='_blank'><FaFacebookSquare /></Link>
                <Link to='https://www.instagram.com/cosmicfandomcreations/' target='_blank'><FaInstagram /></Link>
            </IconContext.Provider>
        </div>
        <img src={logo} alt='Cosmic Fandom Logo' />
        <ul>
            <li><NavLink to='/'>Home</NavLink></li>
            <li><NavLink to='/tees'>Tees</NavLink></li>
            <li><NavLink to='/goods'>Goods</NavLink></li>
            <li><NavLink to='/phones'>Cases</NavLink></li>
        </ul>
        </nav>
    )
}

export default Navbar;