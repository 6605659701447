import { createContext, useState } from "react";

export const ThemeContext = createContext();

const Provider = ({ children }) => {
    const [lightMode, setLightMode] = useState(false);
    const toggleLightMode = () => {
        setLightMode(!lightMode);
    }
    return <ThemeContext.Provider value={{lightMode, toggleLightMode}}>
                {children}
            </ThemeContext.Provider>
}

export default Provider;