import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import redbubble from '../images/redbubble-red.webp';
import teepublic from '../images/teepublic.webp'
import wildestdreams from '../images/wilddreams-tee.webp';
import karma from '../images/karma-tee.webp';
import seven from '../images/seven-tee.webp';
import btf from '../images/btf-tee.webp';
import rationalcreature from '../images/rational-creature-tee.webp';
import austentatious from '../images/austentatious-tee.webp';
import pandp from '../images/pride&prejudice-tee.webp';
import fashioned from '../images/fashioned-tee.webp';
import bigleb from '../images/big-leb-tee.webp';
import ubelong from '../images/ubelong-tee.webp';
import pemberley from '../images/pemberley-tee.webp';
import man from '../images/man-tee.webp';
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const Tees = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <div>
        <Helmet>
            <title>T-Shirt Designs by Cosmic Fandom - Creative Designs for Tees</title>
            <meta
                name="description"
                content="Shop your favorite uniquely crafted T-shirt designs inspired by a love of music, movies, TV, literature and art. All available for purchase on a range of Tees."
            />
            <link rel='canonical' href='https://cosmicfandom.com/tees' />
        </Helmet>
        <div className="App">
            <div className="App-header">
                <Navbar />
                <div className='home'>
                    <motion.div className="home-title" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h2>Cosmic Creations</h2>
                        <h3>T-Shirts</h3>
                    </motion.div>
                    <motion.header className="home-text" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h1>T-Shirt Designs</h1>
                        <p>Shop here for your favorite uniquely crafted designs inspired by a love of music, movies, TV, literature and art.</p>
                        <p>Our designs are also available on: <br/> Dresses, Kids Clothes, Posters, Stickers, Mugs, Phone/Tablet Cases, Hats, Bags, Mats, Scarfs, Cushions, Throws and more...</p> 
                        <p>Purchase of all designs is made through RedBubble and TeePublic</p> 
                    </motion.header>
                </div>
            </div>
            <div className="display">
            <motion.div className='designs' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                <div className="contain">
                    <div className='item'>
                        <img src={wildestdreams} alt='wildest dreams - Taylor Swift' />
                        <h2>Wildest Dreams</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Wildest-Dreams-Staring-at-the-Sunset-by-Cosmic-Fandom/149085985.WFLAH?asc=u' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49947560-wildest-dreams-staring-at-the-sunset' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={karma} alt='karma vibe - Taylor Swift' />
                        <h2>Me and Karma Vibe Like That</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Me-and-Karma-Vibe-Like-That-by-Cosmic-Fandom/149265694.IJ6L0' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49701776-me-and-karma-vibe-like-that?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={seven} alt='Seven - Taylor Swift' />
                        <h2>Seven</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Seven-Girl-Swings-Over-the-Creek-by-Cosmic-Fandom/149320570.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49735996-seven-girl-swings-over-the-creek?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={btf} alt='back to the future' />
                        <h2>Back to the Future</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Back-To-The-Future-Lightning-Strikes-DeLorean-NBCUniversal-by-Cosmic-Fandom/150027956.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={fashioned} alt='mad men - old fashioned' />
                        <h2>Mad Men - Old Fashioned</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Old-Fashioned-Don-Sitting-at-the-Bar-by-Cosmic-Fandom/149734649.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49949910-old-fashioned-don-sitting-at-the-bar?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={bigleb} alt='big lebowski' />
                        <h2>The Big Lebowski</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/The-Big-Lebowski-Attire-NBCUniversal-by-Cosmic-Fandom/149256808.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={rationalcreature} alt='Austen Persuasion' />
                        <h2>Persuasion</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Jane-Austen-Rational-Creatures-Persuasion-by-Cosmic-Fandom/150908082.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49671125-jane-austen-rational-creatures-persuasion?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={austentatious} alt='Austentatious' />
                        <h2>Austentatious</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Jane-Austen-Emma-Quote-and-silhouette-by-Cosmic-Fandom/149072597.QUQES' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49701674-jane-austen-emma-quote-and-silhouette?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={pandp} alt='pride & prejudice' />
                        <h2>Pride & Prejudice</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Pride-and-Prejudice-Jane-Austen-by-Cosmic-Fandom/149291289.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/t-shirt/49701903-pride-and-prejudice-jane-austen?store_id=2837086' target="_blank"><img src={teepublic} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={pemberley} alt='Austen Pemberley' />
                        <h2>Mistress of Pemberley</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Jane-Austen-Mistress-of-Pemberley-by-Cosmic-Fandom/149085013.QUQES' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={ubelong} alt='U Belong with Me - Taylor Swift' />
                        <h2>U Belong With Me</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/Why-can-t-You-See-You-Belong-With-Me-by-Cosmic-Fandom/149126479.WFLAH' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={man} alt='Id be the man - Taylor Swift' />
                        <h2>I'd Be the Man</h2>
                        <h3>Purchase from:</h3>
                        <Link to='https://www.redbubble.com/i/t-shirt/I-d-Be-the-Man-by-Cosmic-Fandom/149105368.240QL' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                </div>
            </motion.div>
            </div>
            <Footer />
        </div>
        </div>
    )
}

export default Tees;