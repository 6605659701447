import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import Designs from "../components/Designs";
import { CORE_DATA } from "../data";

const MusicDesigns = () => {
    const variants = {
        hidden: {
            y: '3vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <>
            <div className="App">
                <Helmet>
                    <title>Music Designs - Creative Designs from the World of Music</title>
                    <meta
                        name="description"
                        content="Shop your favorite uniquely crafted music designs inspired by a love of popular music. All available for purchase on an extensive range of products."
                    />
                    <link rel='canonical' href='https://cosmicfandom.com/musicdesigns' />
                </Helmet>
                <div className="App-header">
                    <Navbar />
                    <div className='home'>
                        <div className="home-title">
                            <h2>Cosmic Creations</h2>
                            <h3>Music Inspired Designs</h3>
                        </div>
                        <header className="home-text">
                            <h1>Music Designs</h1>
                            <p>We specialise in designing unique pop culture art beautifully presented
                                on a range of everyday, must have, accessories and drinkware.</p>
                            <p>Be sure to check out how the designs look on t-shirts, hats, mugs and posters.</p> 
                        </header>
                    </div>
                </div>
                <div className="display">
                <motion.div className='designs' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className="contain">
                        <Designs {...CORE_DATA[84]}/>
                        <Designs {...CORE_DATA[85]}/>
                        <Designs {...CORE_DATA[1]}/>
                        <Designs {...CORE_DATA[3]}/>
                        <Designs {...CORE_DATA[4]}/>
                        <Designs {...CORE_DATA[6]}/>
                        <Designs {...CORE_DATA[8]}/>
                        <Designs {...CORE_DATA[9]}/>
                        <Designs {...CORE_DATA[10]}/>
                        <Designs {...CORE_DATA[11]}/>
                        <Designs {...CORE_DATA[12]}/>
                        <Designs {...CORE_DATA[66]}/>
                        <Designs {...CORE_DATA[68]}/>
                        <Designs {...CORE_DATA[73]}/>
                        <Designs {...CORE_DATA[13]}/>
                        <Designs {...CORE_DATA[18]}/>
                        <Designs {...CORE_DATA[38]}/>
                        <Designs {...CORE_DATA[74]}/>
                        <Designs {...CORE_DATA[82]}/>
                </div>
                </motion.div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default MusicDesigns;