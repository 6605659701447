import './App.css';
import './queries.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import  Home  from './pages/Home';
import  Tees  from './pages/Tees';
import  Mugs  from './pages/Mugs';
import  Phones  from './pages/Phones';
import MusicDesigns from './pages/Music_Designs';
import MovieDesigns from './pages/Movie_Designs';
import LiteraryDesigns from './pages/Literary_Designs';
import PhilosophyDesigns from './pages/Philosophy_Designs';
import ReactGA from "react-ga4";
import Contact from './pages/Contact';
import About from './pages/About';
import ArtDesigns from './pages/Art_Designs';
import SeasonalDesigns from './pages/Seasonal_Designs';

const TRACKING_ID = 'G-NK11R0DGQG';
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: "/", element: <Home />,
  },
  {
    path: '/tees', element: <Tees />,
  },
  {
    path: '/goods', element: <Mugs />,
  },
  {
    path: '/phones', element: <Phones />,
  },
  {
    path: '/musicdesigns', element: <MusicDesigns />,
  },
  {
    path: '/moviedesigns', element: <MovieDesigns />,
  },
  {
    path: '/literarydesigns', element: <LiteraryDesigns />,
  },
  {
    path: '/philosophydesigns', element: <PhilosophyDesigns />,
  },
  {
    path: '/artdesigns', element: <ArtDesigns />,
  },
  {
    path: '/seasonaldesigns', element: <SeasonalDesigns />,
  },
  {
    path: '/about', element: <About />,
  },
  {
    path: '/contact', element: <Contact />,
  }
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
