import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import redbubble from '../images/redbubble-red.webp';
import teepublic from '../images/teepublic.webp'
import wildestdreams from '../images/wilddreams-phone.webp';
import karma from '../images/karma-phone.webp';
import seven from '../images/seven-phone.webp';
import btf from '../images/btf-phone.webp';
import rationalcreature from '../images/rational-creature-phone.webp';
import austentatious from '../images/austentatious-phone.webp';
import pandp from '../images/pride&prejudice-phone.webp';
import fashioned from '../images/fashioned-phone.webp';
import bigleb from '../images/big-leb-phone.webp';
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const Phones = () => {
    const variants = {
        hidden: {
            y: '5vh',
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: 'easeInOut'}
        }
    }

    return(
        <div className="App">
            <Helmet>
                <title>Phone and Tablet Case Designs by Cosmic Fandom</title>
                <meta
                    name="description"
                    content="Shop your favorite uniquely crafted Phone and Tablet Case designs inspired by a love of music, movies, TV, literature and art."
                />
                <link rel='canonical' href='https://cosmicfandom.com/phones' />
            </Helmet>
            <div className="App-header">
                <Navbar />
                <div className='home'>
                    <motion.div className="home-title" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h2>Cosmic Creations</h2>
                        <h3>Phone Cases</h3>
                    </motion.div>
                    <motion.header className="home-text" variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                        <h1>Phone Case Designs</h1>
                        <p>Shop here for your favorite uniquely crafted designs inspired by a love of music, movies, TV, literature and art.</p>
                        <p>Our designs are also available on: <br/> T-Shirts, Dresses, Kids Clothes, Posters, Stickers, Mugs, Hats, Bags, Mats, Scarfs, Cushions, Throws and more...</p> 
                        <p>Purchase of all designs is made through RedBubble and TeePublic</p> 
                    </motion.header>
                </div>
            </div>
            <div className="display">
            <motion.div className='designs' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                <div className="contain">
                <div className='item'>
                        <img src={wildestdreams} alt='wildest dreams' />
                        <h4>Wildest Dreams</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Wildest-Dreams-Staring-at-the-Sunset-by-Cosmic-Fandom/149085985.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49947560-wildest-dreams-staring-at-the-sunset' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={karma} alt='karma vibe' />
                        <h4>Me and Karma Vibe Like That</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Me-and-Karma-Vibe-Like-That-by-Cosmic-Fandom/149265694.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49701776-me-and-karma-vibe-like-that?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={seven} alt='Seven' />
                        <h4>Seven</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Seven-Girl-Swings-Over-the-Creek-by-Cosmic-Fandom/149320570.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49735996-seven-girl-swings-over-the-creek?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={btf} alt='back to the future' />
                        <h4>Back to the Future</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Back-To-The-Future-Lightning-Strikes-DeLorean-NBCUniversal-by-Cosmic-Fandom/150027956.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={fashioned} alt='mad men' />
                        <h4>Mad Men - Old Fashioned</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Old-Fashioned-Don-Sitting-at-the-Bar-by-Cosmic-Fandom/149734649.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49949910-old-fashioned-don-sitting-at-the-bar?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={bigleb} alt='big lebowski' />
                        <h4>The Big Lebowski</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/The-Big-Lebowski-Attire-NBCUniversal-by-Cosmic-Fandom/149256808.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                    </div>
                    <div className='item'>
                        <img src={rationalcreature} alt='Austen Persuasion' />
                        <h4>Persuasion</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Jane-Austen-Rational-Creatures-Persuasion-by-Cosmic-Fandom/150908082.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49671125-jane-austen-persuasion-rational-creatures?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={austentatious} alt='Austentatious' />
                        <h4>Austentatious</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/samsung-case/Jane-Austen-Emma-Quote-and-silhouette-by-Cosmic-Fandom/149072597.WWHTZ' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49701674-jane-austen-emma-quote-and-silhouette?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                    <div className='item'>
                        <img src={pandp} alt='pride & prejudice' />
                        <h4>Pride & Prejudice</h4>
                        <h4>Purchase from:</h4>
                        <Link to='https://www.redbubble.com/i/iphone-case/Pride-and-Prejudice-Jane-Austen-by-Cosmic-Fandom/149291289.9YLDE' target="_blank"><img src={redbubble} alt='RedBubble' /></Link>
                        <h4>or</h4>
                        <Link to='https://www.teepublic.com/phone-case/49701903-pride-and-prejudice-jane-austen?store_id=2837086' target="_blank"><img src={teepublic} alt='TeePublic' /></Link>
                    </div>
                </div>
            </motion.div>
            </div>
            <Footer />
        </div>
    )
}

export default Phones;