import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import Designs from "../components/Designs";
import { CORE_DATA } from "../data";


const MovieDesigns = () => {
        const variants = {
            hidden: {
                y: '3vh',
                opacity: 0
            },
            visible: {
                y: 0,
                opacity: 1,
                transition: { duration: 1, ease: 'easeInOut'}
            }
        }
    return(
        <>
            <div className="App">
                <Helmet>
                    <title>Movie Designs - Creative Designs from the World Film & TV</title>
                    <meta
                        name="description"
                        content="Shop your favorite uniquely crafted movie designs inspired by a love of film and TV. All available for purchase on an extensive range of products."
                    />
                    <link rel='canonical' href='https://cosmicfandom.com/moviedesigns' />
                </Helmet>
                <div className="App-header">
                    <Navbar />
                    <div className='home'>
                        <div className="home-title">
                            <h2>Cosmic Creations</h2>
                            <h3>Film & TV Inspired Designs</h3>
                        </div>
                        <header className="home-text">
                            <h1>Film & TV Designs</h1>
                            <p>We specialise in designing unique pop culture art beautifully presented
                                on a range of everyday, must have, accessories and drinkware.</p>
                            <p>Be sure to check out how the designs look on t-shirts, hats, mugs and posters.</p> 
                        </header>
                    </div>
                </div>
                <div className="display">
                <motion.div className='designs' variants={variants} initial='hidden' whileInView='visible' viewport={{once: true}}>
                    <div className="contain">
                        <Designs {...CORE_DATA[16]}/>
                        <Designs {...CORE_DATA[21]}/>
                        <Designs {...CORE_DATA[44]}/>
                        <Designs {...CORE_DATA[43]}/>
                        <Designs {...CORE_DATA[45]}/>
                        <Designs {...CORE_DATA[19]}/>
                        <Designs {...CORE_DATA[20]}/>
                        <Designs {...CORE_DATA[36]}/>
                </div>
                </motion.div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default MovieDesigns;