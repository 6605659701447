import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";

const Home = () => {
  const variants = {
    hidden: {
        y: '5vh',
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1, ease: 'easeInOut'}
    }
}

    return(
      <div className="App">
        <div className="App-header">
          <Navbar />
          <div className='home'>
            <motion.div className="home-title" variants={variants} initial='hidden' animate='visible' viewport={{once: true}}>
              <h2>Cosmic Creations</h2>
              <h3>out of this world</h3>
            </motion.div>
            <motion.header className="home-text" variants={variants} initial='hidden' animate='visible' viewport={{once: true}}>
              <h1>Welcome to our store!</h1>
              <p>Shop here for your favorite uniquely crafted designs inspired by a love of music, movies, TV, literature and art.</p>
              <p>Our designs are available on: <br/> T-Shirts, Dresses, Kids Clothes, Posters, Stickers, Mugs, Phone/Tablet Cases, Hats, Bags, Mats, Scarfs, Cushions, Throws and more...</p> 
              <p>Purchase of all designs is made through RedBubble and TeePublic</p>
            </motion.header>
          </div>
          <Gallery />
          <Footer />
        </div>
    </div>
    )
}

export default Home;