import { useState, useRef } from "react";
import { db } from '../firebaseConfig.js';
import { addDoc, collection, Timestamp } from 'firebase/firestore';

const Form = () => {
    const [to, setTo] = useState('martinandsoares@gmail.com');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const formRef = useRef();
    const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await sendContactForm({
        name: e.target[0].value,
        email: e.target[1].value,
        subject: e.target[2].value,
        message: e.target[3].value,
      });
      if (res === 0) {
        setTo("martinandsoares@gmail.com")
        setName("")
        setEmail("")
        setSubject("")
        setMessage("Thank you! Your contact form was successfully submitted!");
      } else {
        setMessage("Something went wrong! Please try again");
      }
    };

    const sendContactForm = async ({ name, email, subject, message }) => {
        try {
          const ref = collection(db, "contact");
          await addDoc(ref, {
            to,
            name,
            email,
            subject,
            message,
            sentAt: Timestamp.now().toDate(),
          });
          return 0;
        } catch (err) {
          console.log(err)
          return -1;
        }
      };

    return(
        <div className='form'>
                <form onSubmit={handleSubmit}>
                    <label>Name:</label>
                    <input type='text' 
                    onChange={(e) => setName(e.target.value)}
                    value={name}  
                    required />
                    <label>Email:</label>
                    <input type='email' 
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required />
                    <label>Subject:</label>
                    <input type='text' 
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    required />
                    <label>Message:</label>
                    <textarea type='text'
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    required />
                    <button>Send</button>
                </form>
            </div>
    )
}

export default Form;